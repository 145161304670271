<script>
import navMenuItems from '@/navigation/vertical'
import VerticalNavMenuItems
  from '@core/layouts/layout-vertical/components/vertical-nav-menu/components/vertical-nav-menu-items/VerticalNavMenuItems.vue'

export default {
  components: { VerticalNavMenuItems },
  setup() {
    return {
      navMenuItems,
    }
  },
}
</script>

<template>
  <vertical-nav-menu-items
    :items="navMenuItems"
    class="mobile-nav"
  />
</template>

<style lang="scss">
  @media screen and (max-width: 1200px) {
    .footer{
      padding-bottom: 85px;
    }
  }
  .mobile-nav {
    background: #ffffff;
    position: fixed;
    bottom: 0;
    height: 65px;
    width: 100%;
    display: flex;
    justify-content: space-around;
    box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.3);
    padding-left: 0;
    margin-bottom: 0;
    border-top: 1px solid #eee;
    .nav-item{
      display: flex;
      justify-content: center;
      align-items: center;
      a{
        span{
          display: none;
        }
        svg{
          width: 24px;
          height: 24px;
          stroke: #777;
        }
      }
      &.active{
        a{
          svg{
            stroke: #20ad95;
            width: 28px;
            height: 28px;
          }
        }
      }
    }
  }
  @media screen and (min-width: 1200px) {
    .mobile-nav {
      display: none!important;
    }
  }
</style>
